.mio-componente {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.card {
  display: flex;
  flex-direction: row;
  max-width: 70%;
  margin: 20px auto;
  border: 1px #f1f1f1;
  /* Correggi la sintassi della proprietà border */
  border-radius: 60px;
  overflow: hidden;
}

.immagine img {
  width: 100%;
  height: 100%;
  border-radius: 60px;
}

.immagine {
  flex: 1;
}

.testo {
  flex: 2;
  padding: 20px;
  font-family: 'Futura PT', sans-serif;
  /* Utilizza il font "Futura PT" e specifica sans-serif come fallback */
  text-align: center;
}

.testo h2 {
  margin-bottom: 10px;
  text-align: center;
  font-size: 2.0vw;
}

.testo p {
  font-size: 1.2vw;
}

.titoloservizio {
  text-align: center;
  /* Centra il contenuto all'interno del container */
  padding: 100px;
}

.testos {
  white-space: pre-line;
  /* Consente al testo di andare a capo */
  font-family: 'Futura PT', sans-serif;
  font-size: 80px;
  /* Imposta la dimensione del testo */
  color: #000;
  /* Imposta il colore del testo */
  /* Altri stili se necessari */
}

/* Aggiorna questo stile per invertire l'ordine delle colonne */
.card {
  flex-direction: row-reverse;
  /* Inverti l'ordine delle colonne per la classe "inversione" */
}

.inversione .colonna.immagine {
  border-radius: 0 60px 60px 0;
  /* Adatta i bordi per l'immagine invertita */
}

@media (max-width: 768px) {
  .card {
    flex-direction: column;
    max-width: 90%;
    border-radius: 30px;
  }

  .immagine img,
  .testo {
    border-radius: 30px 30px 0 0;
  }

  /* Aggiorna questo stile per invertire l'ordine delle colonne su schermi più piccoli */
  .inversione .card {
    flex-direction: column-reverse;
    /* Inverti l'ordine delle colonne per la classe "inversione" su schermi più piccoli */
  }

  .testo h2 {
    font-size: 5.5vw;
  }

  .testo p {
    font-size: 4.0vw;
  }

  .titoloservizio {
    text-align: center;
    /* Centra il contenuto all'interno del container */
    padding: 30px;
  }

  .testos {
    white-space: pre-line;
    /* Consente al testo di andare a capo */
    font-family: 'Futura PT', sans-serif;
    font-size: 5.5vw;;
    color: #000;
    /* Imposta il colore del testo */
    /* Altri stili se necessari */
  }

  .mio-componente {
    margin-top: -60px;
  }
}

@media screen and (min-width: 768px) and (max-width: 2065px) {
  .titoloservizio {
    text-align: center;
    /* Centra il contenuto all'interno del container */
    padding: 30px;
  }

  .testos {
    white-space: pre-line;
    /* Consente al testo di andare a capo */
    font-family: 'Futura PT', sans-serif;
    font-size: xx-large;
    color: #000;
    /* Imposta il colore del testo */
    margin-top: 30px;
  }
 
}