/* Stile CSS per il componente Contatti */
.contatti-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #f0f0f0;
  padding: 100px;
  justify-content: center;
  background-image: url('../Immagini/wall.jpg'); /* Sostituisci con il percorso effettivo dell'immagine */
  background-size: cover; /* Assicura che l'immagine copra l'intera area della navbar */
  background-position: center; /* Centra l'immagine nella navbar */
}

.contatti-info,
.map {
  border: 1px  #ddd;
  box-sizing: border-box;
  padding-right: 3%;
}

h2 {
  font-family: 'Futura PT', sans-serif; /* Utilizza il font "Futura PT" e specifica sans-serif come fallback */
  font-size: 50px;
  margin-bottom: 10px;
  padding:10px
}

p {
  font-family: 'Futura PT', sans-serif; /* Utilizza il font "Futura PT" e specifica sans-serif come fallback */
  font-size: 30px;
  margin-bottom: 20px;
  padding:10px

}

ul {
  list-style: none;
  padding: 0;
}

li {
  font-family: 'Futura PT', sans-serif; /* Utilizza il font "Futura PT" e specifica sans-serif come fallback */
  font-size: 30px;
  margin-bottom: 10px;
}

#map {
  width: 500px; /* Imposta la larghezza desiderata del tuo div */
  height: 300px; /* Imposta l'altezza desiderata del tuo div */
  overflow: hidden; /* Assicurati che l'overflow sia nascosto per evitare che l'immagine esca dal div */
}

#map img {
  width: 100%; /* Imposta la larghezza dell'immagine al 100% del div */
  height: auto; /* Imposta l'altezza automaticamente in base alla larghezza per mantenere le proporzioni originali */
  display: block; /* Rimuovi eventuali spazi bianchi aggiunti alla parte inferiore dell'immagine */
  
}


.map-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  margin: 0;
  padding: 0;
  border: solid 7px #6c757d;
}

/* Media query per dispositivi mobili con larghezza massima di 767px */
@media (max-width: 767px) {
  .map-image {
   
      width: 100%; /* Imposta la larghezza dell'immagine al 100% del div */
      height: auto; /* Imposta l'altezza automaticamente in base alla larghezza per mantenere le proporzioni originali */
      display: block; /* Rimuovi eventuali spazi bianchi aggiunti alla parte inferiore dell'immagine */
      border: solid 2px #6c757d;
    }
  .contatti-info{
    overflow: hidden;
    width: 100%;
    text-align: center;
  }
  .contatti-container{
    padding: 30px;
  }
  .contatti-container h2{
    font-size: 30px;
  }
  .contatti-container p{
    font-size: 20px;
  }
  .contatti-container li  {
    font-size: 20px;
   }
 .contatti-container li span {
  font-size: 25px;
 }
 
}
@media screen and (min-width: 768px) and (max-width: 2065px) {
  .contatti-container  {
    flex-wrap: nowrap;
  }
}