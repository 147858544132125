
.navbar {
  position:fixed; /* Imposta la posizione fissa rispetto alla finestra di visualizzazione */
  top: 0; /* Allinea la navbar in alto */
  left: 0; /* Allinea la navbar a sinistra */
  width: 100%; /* Occupa l'intera larghezza */
  height: auto;
  z-index: 1000; /* Imposta l'ordine di impilamento, maggiore è il valore, più in alto è l'elemento */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  transition: background-color 0.3s ease;
  background-size: cover; /* Assicura che l'immagine copra l'intera area della navbar */
  background-position: center; /* Centra l'immagine nella navbar */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgb(254, 254, 252);; /* Colore di sfondo grigio */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  opacity: 0.95;
}

/* Resto del tuo CSS rimane invariato */
.navbar.show{
  display: none;
}

.logo {
  font-size: 20px;
  font-weight: bold;
}

.nav-links {
  display: flex;
}

.nav-link {
  margin-right: 100px;
  text-decoration: none;
  color: #333;
  /* font-weight: bold; */
  font-size:xx-large;
  font-family: 'Futura PT', sans-serif; /* Utilizza il font "Futura PT" e specifica sans-serif come fallback */
  /* font-style: italic; Applica lo stile corsivo */
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  height: 5px;
  width: 30px;
  background-color: #333;
  margin: 6px 0;
  transition: all 0.3s ease;
}

.bar.open{
  display: none;
}
.close{
  font-size: 30px;
  font-weight: bold;
  display: none;
}

.close.open{
  display: block;
}

.nav-links-mobile {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 140px;
  left: 0;
  right: 0;
  background-color: #fff;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.nav-links-mobile.show {
  display: flex;
}


.logo {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center; /* Centra verticalmente il contenuto della logo */
}

/* Stile dell'immagine del logo */
.logo-image {
  max-width: 200px; /* Larghezza massima di 200px per l'immagine del logo */
  height: auto; /* Mantiene l'aspetto proporzionato regolando automaticamente l'altezza */
  margin-right: 10px; /* Aggiunge uno spazio tra il testo della logo e l'immagine */
}

/* ... (Il resto del tuo CSS rimane invariato) */


@media screen and (max-width: 767px) {
  .nav-links {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  /* Nascondi l'hamburger quando il menu mobile è chiuso */
  .hamburger.open {
     display: none; 

  }


  /* Visualizza il menu mobile quando aperto */
  .nav-links-mobile.show {
    display: flex;
    background-size: cover; /* Assicura che l'immagine copra l'intera area della navbar */
    background-position: right; /* Centra l'immagine nella navbar */
  }


  .logo-image {
    max-width: 100px; /* Larghezza massima di 200px per l'immagine del logo */
    height: auto; /* Mantiene l'aspetto proporzionato regolando automaticamente l'altezza */
    margin-right: 10px; /* Aggiunge uno spazio tra il testo della logo e l'immagine */
  }
  .navbar{
    width: 100%;

    .nav-link {
      margin-inline: 50px;
      text-decoration: none;
      color: #333;
      /* font-weight: bold; */
      font-size:medium;
      font-family: 'Futura PT', sans-serif; /* Utilizza il font "Futura PT" e specifica sans-serif come fallback */
      /* font-style: italic; Applica lo stile corsivo */
      text-align: center;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 2065px) {
  .nav-link {
    margin-right: 50px;
    text-decoration: none;
    color: #333;
    /* font-weight: bold; */
    font-size:x-large;
    font-family: 'Futura PT', sans-serif; /* Utilizza il font "Futura PT" e specifica sans-serif come fallback */
    /* font-style: italic; Applica lo stile corsivo */
  }
  .logo-image {
    max-width: 100px; /* Larghezza massima di 200px per l'immagine del logo */
  }
}