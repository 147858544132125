/* ChiSiamo.css */

.home-section {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* filter: brightness(80%); Modifica il valore per regolare l'opacità dell'immagine */
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}
.testo {
  font-family: 'Futura PT', sans-serif; /* Utilizza il font "Futura PT" e specifica sans-serif come fallback */
  /* font-style: italic; Applica lo stile corsivo */
}


/* ChiSiamo.css */

/* ... Altri stili ... */

.home-section {
  position: relative;
  height: 100vh;
  overflow: hidden;
  transition: background 0.3s ease; /* Aggiungi una transizione per rendere l'effetto più fluido */
}

/* Stili per lo stato "hovered" */
.home-section.hovered {
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)); /* Sostituisci con il tuo colore desiderato */
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ... Altri stili ... */
