.testog {
  font-size: 80px; /* Modifica la dimensione del titolo su schermi più piccoli */
}
.titologalleria {
  text-align: center; /* Centra il contenuto all'interno del container */
  padding: 100px;
}
.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  display: flex;
  gap: 40px;
  justify-content: center;
  margin-bottom: 50px;
  --bs-gutter-x:0;
}

/* Galleria.css */
.cell {
  width: 500px;
  height: 400px;
  overflow: hidden;
  border-radius: 20px;
  margin-right: 10px; /* Puoi rimuovere questo margine se non è necessario */
}

.cell img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Fai in modo che l'immagine copra completamente la cella mantenendo le proporzioni */
  border-radius: 20px; /* Assicurati che l'immagine abbia lo stesso bordo arrotondato della cella */
}

.testo h2 {
  /* padding: 30px; */
  box-sizing: border-box;
  font-family: 'Futura PT', sans-serif; /* Utilizza il font "Futura PT" e specifica sans-serif come fallback */
  text-align: center; /* Centra il testo */
  font-size: 400px;
}

@media (max-width: 767px) {
  .grid {
    padding: 10px; /* Aggiungi spazio ai lati quando si passa alla disposizione a colonna */
  }

  .row {
    flex-direction: column;
    align-items: center;
  }

  .cell img {
    width: 100%;
    display: block;
    -moz-transition: 800ms;
    -webkit-transition: 800ms;
    -o-transition: 800ms;
    transition: 800ms;
    transform: scale(1); /* Modifica la scala a seconda delle tue esigenze */
  }

  .cell {
    border-radius: 120px;
    width: auto;
  }
  .testo {
    font-size: 50px; /* Modifica la dimensione del titolo su schermi più piccoli */
    margin: 10px;
  }

  .testog {
    font-size: 40px; /* Modifica la dimensione del titolo su schermi più piccoli */
    margin: 10px;
  }
  .titologalleria {
    text-align: center; /* Centra il contenuto all'interno del container */
    padding: 50px;
  }
}


/* Aggiungi queste regole di stile al tuo file CSS esistente senza modificarne il resto */

.cell:hover img {
  transform: scale(1); /* Effetto di ingrandimento al passaggio del mouse */
  filter: brightness(70%); /* Riduci la luminosità al passaggio del mouse */
}

.cell::before {
  content: attr(data-text);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 18px;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 10px;
  z-index: 1;
  opacity: 0; /* Imposta l'opacità iniziale a 0 */
  transition: opacity 0.3s ease; /* Aggiungi una transizione per l'opacità */
}

.cell:hover::before {
  opacity: 1; /* Rendi visibile l'elemento prima nascosto al passaggio del mouse */
}

/* Media query per dispositivi mobili con larghezza massima di 767px */
@media (max-width: 767px) {
  .cell:hover img {
    transform: scale(1);
    filter: brightness(100%); /* Ripristina la luminosità su dispositivi mobili */
  }

  .cell:hover::before {
    font-size: 100px;
  }
}

/* Galleria.css */
.cell {
  position: relative;
  overflow: hidden;
}

.cell img {
  width: 100%;
  display: block;
  transition: transform 800ms, filter 800ms; /* Aggiungi anche la transizione per il filtro */
  transform: scale(1);
  object-fit: cover;
  border-radius: 30px;
}

.cell:hover img {
  transform: scale(1);
  filter: brightness(70%);
}

.cell::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(233, 226, 208, 0.8);
  opacity: 0;
  transition: opacity 800ms;
}

.cell:hover::before {
  opacity: 1;
}

.cell figcaption {
  color: #fff;
  padding: 0.5rem;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Futura PT', sans-serif; /* Utilizza il font "Futura PT" e specifica sans-serif come fallback */
  font-size: 50px;
  z-index: 1;
}

.cell:hover figcaption {
  opacity: 1;
}
