.footer{
    background-size: cover; /* Assicura che l'immagine copra l'intera area della navbar */
    background-position: center; /* Centra l'immagine nella navbar */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   }
 .testofooter{
    font-size: large; 
 }
   @media screen and (max-width: 767px) {
   .testofooter {
    
        font-family: 'Futura PT', sans-serif;
        font-size: 10px;
    }
    
   }
